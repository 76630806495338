import { LegendItem } from "../shared/chart.types";

export interface LgComparingBoxplotItem {
    main: LgConvertedBoxplotItem;
    comparing?: LgConvertedBoxplotItem;
    index?: number;
}

export interface LgConvertedBoxplotItem {
    group: string;
    q1?: number | null;
    median?: number | null;
    average?: number | null;
    q3?: number | null;
    interQuantileRange?: number | null;
    min?: number | null;
    max?: number | null;
    legendItem?: ILegendItemDefinition;
}

export interface LgBoxplotDatum {
    key: string;
    value: LgComparingBoxplotItem;
}

export interface LgBoxplotItem {
    group: string;
    itemValue: number;
    itemName: string;
}

export interface LgBoxplotTooltipContext {
    name: string;
    main: LgConvertedBoxplotItem;
    comparing?: LgConvertedBoxplotItem | undefined;
    groupToLegendDefinitionDictionary: { [group: string]: LegendItem | undefined };
    highlighted?: LgBoxplotHighlighted | null | undefined;
    isComparing: boolean;
    isLabelHovered: boolean;
    iconTooltip?: boolean;
}

export enum LgBoxplotLineIndices {
    MainLineVertical = 0,
    MainLineMedian = 1,
    ComparingLineVertical = 2,
    ComparingLineMedian = 3
}

export enum LgBoxplotTypeIndices {
    Main = 0,
    Comparing = 1
}

export enum LgBoxplotHighlighted {
    Max = "Max",
    UpperQuartile = "UpperQuartile",
    Median = "Median",
    LowerQuartile = "LowerQuartile",
    Min = "Min"
}

export interface LgBoxplotLabelDimensions {
    width: number;
    height: number;
}

export interface ILegendItemDefinition {
    color: string;
    name: string;
    order?: number | undefined;
}
